<template>
  <div class="all">
    <div class="tip" v-if="!type">
      <div class="tu1" v-if="dateList.status == 2">
        <img src="../../../public/images-order/222222ico_wctb.png" alt="" />
      </div>
      <div
        class="tu1"
        v-if="
          dateList.status == 1 || dateList.status == 3 || dateList.status == 4
        "
      >
        <img src="../../../public/images-order/ico_cw4444.png" alt="" />
      </div>
      <div class="zi1" v-if="dateList.status == 2">支付成功</div>
      <div class="zi1" v-if="dateList.status == 1">未支付</div>
      <div class="zi1" v-if="dateList.status == 4">已作废</div>
      <div class="zi1" v-if="dateList.status == 3">已失效</div>
    </div>
    <div v-else class="tip">
      <div class="tu1" v-if="dateList.status == 2">
        <img src="../../../public/images-order/222222ico_wctb.png" alt="" />
      </div>
      <div class="tu1" v-if="dateList.status == 1">
        <img src="../../../public/images-order/ico_cw4444.png" alt="" />
      </div>
      <div class="zi1" v-if="dateList.status == 1">未支付</div>
      <div class="zi1" v-if="dateList.status == 2">支付成功</div>
    </div>
    <div class="tu2">
      <img
        :src="
          !type
            ? dateList.course_img
            : type == 2
            ? dateList.member_cover_img
            : dateList.workbook_img_url
        "
        alt=""
      />
    </div>
    <div class="kuai">
      <div class="zi2">
        {{
          !type
            ? dateList.course_name
            : type == 2
            ? dateList.member_name
            : dateList.workbook_name
        }}
      </div>
      <div class="zi3">
        <span>
          订单编号：{{ !type ? dateList.number : dateList.order_number }}
        </span>
        <span>付款用户：{{ dateList.uname }}</span>
      </div>
    </div>
    <div class="clear"></div>
    <div class="zi4">
      时间：
      {{
        !type
          ? dateList.orderTime
          : dateList.order_time | formatTimeStamp('yyyy-MM-dd hh:mm')
      }}
    </div>
    <div class="zi5">单位：元</div>
    <div class="clear"></div>
    <table :class="dateList.installment_type > 0 ? 'w25' : 'w50'">
      <tr class="first">
        <td>{{ !type ? '订单金额 ' : type == 2 ? '会员规格' : '售价' }}</td>
        <td v-if="dateList.installment_type > 0">分期</td>
        <td v-if="dateList.installment_type > 0">利息</td>
        <td>实付金额</td>
      </tr>
      <tr>
        <td class="zi6">
          <span v-if="!type || type == 3">
            {{ !type ? dateList.costprice : dateList.actual_price }}
          </span>
          <span v-else>{{ dateList.member_vaild_day }}天</span>
        </td>
        <template v-if="dateList.installment_type > 0">
          <td class="zi6">
            {{ dateList.installment_type | getInstallmentTypeText }}期
          </td>
          <td class="zi6">￥{{ dateList.interest }}元</td>
        </template>

        <td class="zi7">
          <div>
            <span v-if="!type">
              {{ dateList.courseprice }}
            </span>
            <span v-else>
              {{ dateList.actual_price }}
            </span>
          </div>

          <div v-if="dateList.couponList && dateList.couponList.length > 0">
            <span>使用了优惠券</span>
            <el-popover
              placement="bottom"
              width="250"
              style="margin-left:3px"
              trigger="hover"
            >
              <template slot="reference">
                <div class="vm help" :class="icon2"></div>
              </template>
              <el-table
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="dateList.couponList"
              >
                <el-table-column
                  label="优惠券名称"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  label="抵扣金额(元)"
                  prop="discount_amount"
                ></el-table-column>
              </el-table>
            </el-popover>
          </div>
          <div v-if="!type">
            <span v-if="dateList.cou_id > 0">
              使用了优惠码 优惠金额：{{ dateList.coupon_price }}
            </span>
            <span v-if="dateList.order_type == 2">参与了拼团</span>
            <span v-if="dateList.order_type == 3">
              {{ dateList.exchange_details_id==0?'使用了兑换码':'使用了提取码' }}
            </span>
            <span v-if="dateList.fission_id > 0">
              参与了裂变活动 佣金金额：{{ dateList.brokerage }}
            </span>
            <span v-if="dateList.is_seckill == 1">
              参与了秒杀活动
            </span>
            &nbsp;
            <!-- <span
              v-if="
                !dateList.couponList &&
                  dateList.couponList.length == 0 &&
                  (dateList.cou_id == 0 ||
                    (dateList.order_type == 2) == 0 ||
                    dateList.fission_id)
              "
            >
              {{ dateList.courseprice }}
            </span> -->
          </div>
        </td>
      </tr>

      <!-- <tr v-if="dateList.cou_id > 0"> 
        <td></td>
        <template v-if="dateList.installment_type > 0">
          <td></td>
          <td></td>
        </template>
        <td v-if="dateList.pre_way == 1">
          <span class="zi8">优惠金额 （元）</span>
          <span class="zi7">{{ dateList.coupon_price }}</span>
        </td>
        <td v-if="dateList.pre_way == 2">
          <span class="zi8">折扣优惠（折）</span>
          <span class="zi7">{{ dateList.discount }}</span>
        </td>
      </tr> -->

      <!-- <tr v-if="dateList.brokerage > 0">
        <td></td>
        <td>
          <span class="zi8">佣金</span>
          <span class="zi7">{{ dateList.brokerage }}</span>
        </td>
      </tr> -->
    </table>

    <div v-if="!type">
      <div class="zuofei" v-if="dateList.status == 2 && showUnsetBtn">
        <el-button
          type="text"
          :closeOnClickModal="false"
          @click="zuofei(dateList.$index, dateList.order_id)"
          style="color: #fff"
        >
          <span>作废</span>
        </el-button>
      </div>
      <div class="zuofei" v-if="dateList.status == 1 || dateList.status == 3">
        <el-button
          type="text"
          :closeOnClickModal="false"
          @click="del(dateList.$index, dateList.order_id)"
          style="color: #fff"
        >
          <span>删除</span>
        </el-button>
      </div>
      <div class="zuofei" v-if="dateList.status == 4">
        <el-button
          type="text"
          :closeOnClickModal="false"
          @click="huifu(dateList.$index, dateList.order_id)"
          style="color: #fff"
        >
          <span>恢复</span>
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
// import fade from "@/components/FadeAnimation";

export default {
  name: 'orderDetail',

  props: {
    order_id: String,
  },

  data() {
    return {
      icon2: '',
      type: this.$route.query.type,
      data_id: this.$route.query.data_id,
      member_order_id: this.$route.query.member_order_id,
      dateList: [],
    }
  },

  filters: {
    getInstallmentTypeText(type) {
      let text
      switch (Number(type)) {
        case 1:
          text = 3
          break
        case 2:
          text = 6
          break
        case 3:
          text = 12
          break
      }
      return text
    },
  },

  created() {
    if (!this.$route.query.type) {
      this.getDate()
    } else if (this.$route.query.type == 2) {
      this.orderDetails()
    } else {
      this.seeOrder()
    }
  },
  computed: {
    idvalue() {
      return this.$route.query.type == 2
        ? this.$route.query.member_order_id
        : this.$route.query.type == 3
        ? this.$route.query.workbook_order_id
        : this.$route.query.data_id
    },

    //作废
    showUnsetBtn() {
      return this.$store.state.roots.includes(140)
    },
  },
  methods: {
    seeOrder() {
      this.$http({
        url: '/workbook/seeOrder',
        data: {
          workbook_order_id: this.idvalue,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
    orderDetails() {
      this.$http({
        url: '/courseMember/orderDetails',
        data: {
          member_order_id: this.idvalue,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
    zuofei(index, val1) {
      this.$confirm(
        '作废后学员已购状态变为未购状态，是否作废订单？',
        '作废订单',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      ).then(() => {
        this.$http({
          url: 'Order/unsetOrder',
          data: {
            ids: [val1],
            type: 1,
          },
          callback: () => {
            this.$root.prompt({
              msg: '作废成功',
              type: 'success',
            })
            this.getDate()
          },
          error: () => {
            this.$root.prompt('作废失败')
          },
        })
      })
    },

    //删除订单
    del(index, val1) {
      this.$confirm('是否删除该订单？', '删除订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
      }).then(() => {
        // console.log(index,val1)
        this.$http({
          url: '/Order/unsetOrder',
          data: {
            ids: [val1],
            type: 3,
          },
          callback: () => {
            this.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            this.$router.go(-1)
          },
          error: () => {
            this.$root.prompt('删除失败')
          },
        })
      })
    },
    //恢复订单
    huifu(index, val1) {
      this.$confirm('是否恢复该订单？', '恢复订单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
      }).then(() => {
        this.$http({
          url: '/Order/unsetOrder',
          data: {
            ids: [val1],
            type: 2,
          },
          callback: () => {
            this.$root.prompt({
              msg: '恢复成功',
              type: 'success',
            })
            this.getDate()
          },
          error: error => {
            if (error.code == 205) {
              this.$root.prompt(error.info)
            }
          },
        })
      })
    },

    getDate() {
      this.$http({
        url: '/Order/OrderDetail',
        data: {
          order_id: this.idvalue,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },

    zf() {
      this.$confirm(
        '作废后学员已购状态变为未购状态，是否作废订单？',
        '作废订单',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: '订单作废成功！',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table__header-wrapper {
  line-height: 10px;
  height: 45px;
}
::v-deep .el-table__cell {
  padding: 7px 0 !important;
}
.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url('~@ass/img/1.2.9/ico_wh@2x.png');
  background-size: 100%;
  &.icon2 {
    width: 8px;
    height: 13px;
    background: url('~@ass/img/1.2.9/ico_xljt.png');
    background-size: 100%;
  }
}
.all {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}
.tip {
  width: 100%;
  margin-top: 20px;
  padding-top: 21px;
  box-sizing: border-box;
  height: 80px;
  background: rgba(244, 245, 250, 1);
}
.tu1 {
  width: 38px;
  height: 38px;
  float: left;
  margin-left: 20px;
}
.zi1 {
  width: 64px;
  height: 21px;
  font-size: 16px;
  float: left;
  margin-top: 7px;
  margin-left: 20px;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
}
.el-button span {
  width: 99px;
  text-align: center;
  display: block;
}

.tu2 {
  width: 143px;
  height: 80px;
  margin-top: 30px;
  float: left;
  background: rgba(110, 110, 110, 1);
  border-radius: 7px;
}
.tu2 img {
  width: 143px;
  height: 80px;
}
.kuai {
  width: calc(100% - 163px);
  float: left;
  margin-left: 20px;
}
.clear {
  clear: both;
}
.zi2 {
  margin-top: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.zi3 {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  margin-top: 24px;
}
.zi3 span:first-child {
  margin-bottom: 6px;
  display: block;
}

.zi4 {
  width: 141px;
  height: 16px;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  margin-top: 30px;
  float: left;
}
.zi5 {
  width: 48px;
  height: 16px;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  float: right;
  margin-left: 950px;
  margin-top: 30px;
}
table {
  width: 100%;
  height: 145px;
  border-collapse: collapse;
  margin-top: 10px;
}
table tr {
  height: 48px;
}
table tr td {
  border: 1px solid rgba(227, 227, 227, 1);
  text-align: right;
  padding: 19px;
  box-sizing: border-box;
}
.w25 tr td {
  width: 25%;
}
.w50 tr td {
  width: 50%;
}
.first {
  background: rgba(244, 245, 250, 1);
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.zi6 {
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
}
.zi7 {
  font-size: 16px;
  color: rgba(255, 53, 53, 1);
  line-height: 21px;
  width: 600px;
}
.zi7 span {
  font-size: 12px;
}
.zi8 {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  float: left;
  margin-top: 4px;
}
.zuofei {
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  line-height: 20px;
  width: 110px;
  height: 42px;
  border: 0px;
  background: rgba(255, 53, 53, 1);
  border-radius: 4px;
  padding-top: 3px;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 54px;
  margin-top: 40px;
}
</style>
